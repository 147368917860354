<template>
  <!-- main-wrapper start -->
  <div class="main-wrapper">
    <div class="home_advert_list">
      <el-button @click="AddHomeCarouselAdvertsWindow({Sort:0})">新增</el-button>
      <el-table :data="HomeCarouselAdverts" style="width: 100%" :height="hieght">
        <el-table-column prop="Sort" label="排序" width="120" />
        <el-table-column prop="Description" label="说明" width="120" />
        <el-table-column prop="CompleteFileName" label="图片" width="320">
          <template #default="scope">
            <el-image
              style="width: 100px; height: 100px"
              :src="scope.row.CompleteFileName"
              :fit="'contain'"
            ></el-image>
          </template>
        </el-table-column>
        <el-table-column label="操作">
          <template #default="scope">
            <el-button
              size="small"
              @click="AddHomeCarouselAdvertsWindow(scope.row)"
              >编辑</el-button
            >
            <el-button
              size="small"
              type="danger"
              @click="DeleteAdvert(scope.$index, scope.row)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>

    <el-dialog v-model="HomeCarouselAdvertsDisplay" title="" width="80%">
      <el-form ref="form">
        <el-form-item label="说明">
          <el-input v-model="HomeCarouselAdvertModel.Description"></el-input>
        </el-form-item>
        <el-form-item label="排序">
          <el-input-number v-model="HomeCarouselAdvertModel.Sort"></el-input-number>
        </el-form-item>
        <el-form-item label="图片">
          <el-upload
            class="avatar-uploader"
            :action="UploadImageUrl"
            :headers="headersImage"
            :show-file-list="false"
            :on-success="handleAvatarSuccess"
            :before-upload="beforeAvatarUpload"
          >
            <img
              v-if="HomeCarouselAdvertModel.CompleteFileName"
              :src="HomeCarouselAdvertModel.CompleteFileName"
              class="avatar"
            />
            <el-icon v-else class="avatar-uploader-icon"><plus /></el-icon>
          </el-upload>

        </el-form-item>
           <p> 1.轮播图建议尺寸为 1200 * 600 或者 宽度是高度的两倍! </p>
           <p> 2.轮播图大小建议为1M以内 </p>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="HomeCarouselAdvertsDisplay = false"
            >关闭</el-button
          >
          <el-button @click="AddAdverts()">确定</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>
<script >
import axios from "../../../commons/AxiosMethod.js"
import {
  UploadFilled,
  Picture as IconPicture,
  Plus
} from "@element-plus/icons-vue"
import { ElMessageBox } from "element-plus"  
import ProductItem from "../../../components/ProductItem.vue" 

export default {
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  data () {
    return {
      HomeCarouselAdverts: [],
      HomeCarouselAdvertModel: {
        FileName: "",
        Description: "",
        CompleteFileName: "",
        Sort: 0,
        Id: "",
        Relation: "",
        hieght: window.screen.height - 150
      },
      UploadImageUrl: "",
      headersImage: {}, // headers
      HomeCarouselAdvertsDisplay: false
    }
  },
  name: "App",
  mounted () {
    this.UploadImageUrl = axios.GetUrl() + "/api/Upload/ImageFile"
    this.headersImage = {
      Authorization: localStorage.getItem("Authentication_Header")
    }
    this.GetHomeCarouselAdverts()
  },
  components: {  
    ProductItem, 
    UploadFilled,
    IconPicture,
    Plus
  },

  methods: {
    DeleteAdvert (index, item) {
      ElMessageBox.confirm("确定要删除吗?")
        .then(() => {
          const param = {}
          axios.apiMethod(
            "/Admins/Advert/DeleteHomeCarouselAdverts",
            "get",
            { Id: item.Id },
            (result) => {
              if (result.Code == 200) {
                this.GetHomeCarouselAdverts()
                this.HomeCarouselAdvertsDisplay = false
              } else {
                ElMessageBox({
                  message: result.FullMsg,
                  type: "error"
                })
              }
            }
          )
        })
        .catch(() => {
          // catch error
        })
    },
    AddHomeCarouselAdvertsWindow (item) {
      this.HomeCarouselAdvertModel = item
      this.HomeCarouselAdvertsDisplay = true
    },
    // 获取首页滚动推广图片
    GetHomeCarouselAdverts () {
      const param = {}
      axios.apiMethod(
        "/Admins/Advert/GetHomeCarouselAdverts",
        "get",
        param,
        (result) => {
          if (result.Code == 200) {
            this.HomeCarouselAdverts = result.Data
          } else {
            ElMessageBox({
              message: result.FullMsg,
              type: "error"
            })
          }
        }
      )
    },
    handleAvatarSuccess (response, file, fileList) {
      this.HomeCarouselAdvertModel.CompleteFileName = response.Data.ImageFormet
      this.HomeCarouselAdvertModel.FileName = response.Data.FileName
    },
    beforeAvatarUpload () {},
    AddAdverts () {
      const param = this.HomeCarouselAdvertModel

      axios.apiMethod(
        "/Admins/Advert/AddHomeCarouselAdverts",
        "post",
        param,
        (result) => {
          if (result.Code == 200) {
            this.GetHomeCarouselAdverts()
            this.HomeCarouselAdvertsDisplay = false
          } else {
            ElMessageBox({
              message: result.FullMsg,
              type: "error"
            })
          }
        }
      )
    }
  }
}
</script>

<style scoped>
.avatar-uploader,
.el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader,
.el-upload:hover {
  border-color: #409eff;
}
.el-icon.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
</style>
